import React from 'react';

const HeroLayout = ({ children, footer, withHeader }) => {
    return (
        <section className="hero is-fullheight-with-navbar">
            <div className="hero-head"></div>
            <div className={`hero-body ${withHeader ? 'h-spaced' : ''}`}>{children}</div>
            {!!footer && (
                <div
                    className="hero-foot has-text-centered"
                    style={{ borderTop: '1px solid #ddd', paddingTop: '2em' }}
                >
                    {footer}
                </div>
            )}
        </section>
    );
};

export default HeroLayout;
