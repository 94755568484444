import { Link } from 'gatsby';
import React from 'react';
import HeroLayout from '../components/heroLayout';

const NotFoundPage = () => {
    return (
        <HeroLayout>
            <div className="container is-desktop">
                <img
                    className="ma"
                    src="/ranking-fotowoltaiki-logo-web.svg"
                    alt="Ranking Fotowoltaiki - logo"
                />
                <h1 className="title h-acc">Nie znaleziono strony</h1>
                <p className="block">
                    Żądana strona nie została znaleziona. spróbuj wrócić do strony głównej.
                </p>
                <Link to="/">
                    <button className="is-primary button">Strona główna</button>
                </Link>
            </div>
        </HeroLayout>
    );
};

export default NotFoundPage;
